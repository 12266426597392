<template>
  <span>
    <action-dropdown-item
      v-if="!asButton"
      link-class="d-flex align-items-center"
      @click.prevent="removeUser"
    >
      <feather-icon
        size="16"
        icon="TrashIcon"
        class="mr-50"
      />
      <span>Remove</span>
    </action-dropdown-item>
    <action-button
      v-if="asButton"
      variant="outline-none"
      class="btn-icon"
      @click.prevent="removeUser"
    >
      <feather-icon
        icon="TrashIcon"
        size="20"
      />
    </action-button>
  </span>
</template>
<script>
    
import {  
  BDropdownItem, BButton
} from "bootstrap-vue";
    
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import usersService  from "@/services/usersService";
import ActionButton from "@/views/components/common/ActionButton.vue";  
import ActionDropdownItem from "@/views/components/common/ActionDropdownItem.vue";
    
export default {
  name: 'RemoveUser',
  components: {
    ActionButton,
    BDropdownItem,
    BButton,
    ActionDropdownItem
  },
  props:{
    user: {
      type: Object,
      default: () => {},
      required: true
    },
    userId: {
      type:Number,
      required: true
    },
    isClientUser: {
      type: Boolean
    },
    asButton: {
      type: Boolean
    }
  },
 
  methods: {
    async removeUser() {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to remove this participant? They will lose access to this program and all their information will be lost.', {
          title: 'Remove Participant?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Remove',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value) {
            try {
              const data = {
                users: [this.user]
              };
              const result = await this.resolveService(data);
              if (result) {
                this.$toast(makeSuccessToast(result.data.message));
                this.$emit('updated');
              }
            } catch (e) {
              console.log(e);
              const { data } = e.response;
              this.$toast(makeErrorToast(data.message));
              this.$log.error(e);
            }
          }
        });
    },
    resolveService(data) {
      if(this.isClientUser) {
        return usersService.deleteClientParticipantMany(Number(this.$route.params.clientId), data);
      } else {
        const programId = this.$route.params.id;
        return usersService.deleteManyParticipants(programId, data);
      }
    }
  }
};
</script>
     