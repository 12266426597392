<template>
  <section>
    <div v-if="isLoading">
      <aom-skeleton-loader />
    </div>
    <div v-else>
      <b-card>
        <b-row>
          <b-col
            md="6"
          >
            <b-media
              no-body
              class="justify-content-center"
            >
              <b-media-aside>
                <b-avatar
                  variant="primary"
                  :text="userInitials"
                  :src="userProfileImage" 
                  size="8rem"
                />
              </b-media-aside>
            </b-media>
            <h4 class="text-center mt-1">
              {{ user.full_name }}
            </h4>
            <p class="text-center mt-1">
              {{ user.email }}
            </p>
            <p class="text-center mt-1">
              {{ user.phone }}
            </p>
          </b-col>
          <b-col
            md="6"
          >
            <div class="d-flex justify-content-end">     
              <b-dropdown
                text="Action"
                class="ml-2"
                variant="primary"
                size="md"
                right
              >
                <!-- Suspend User -->
                <suspend-user
                  :user="user"
                  :user-id="user.id"
                />
                <remove-user
                  :user="user"
                  :user-id="user.id"
                  :is-client-user="true"
                  @updated="onUserRemoved"
                />
              </b-dropdown>         
            </div>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <b-tabs
          v-model="tabIndex"
          content-class="col-12 mt-1 mt-md-0"
          pills
          card
          nav-wrapper-class="col-md-3 col-12"
          nav-class="nav-left"
        >
          <b-tab lazy>
            <!-- title -->
            <template #title>
              <feather-icon
                icon="ListIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold">Programs</span>
            </template>
            <user-programs-list 
              :client-id="clientId"
              :user-id="userId"
            />
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </section>
</template>

<script>
import { BTabs, BTab, BCard, BMedia, BMediaAside, BRow, BCol, BDropdown, BAvatar } from 'bootstrap-vue';
import usersService from "@/services/usersService";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { makeErrorToast } from "@/libs/utils";
import UserProgramsList from '../programs/UserProgramsList.vue';
import SuspendUser  from '../../participants/actions/SuspendUser';
import RemoveUser from '../../participants/actions/RemoveUser';
export default {
  name: 'Participant',
  components: {
    BTabs,
    BTab,
    BCard,
    AomSkeletonLoader,
    UserProgramsList,
    BMedia,
    BMediaAside,
    BRow, 
    BCol, 
    BDropdown, 
    BAvatar,
    SuspendUser,
    RemoveUser,
  },
  data() {
    return {
      isLoading: true,
      user: undefined,
      tabIndex: 0,
      tabs: ['#programs']
    };
  },
  computed: {
    clientId() {
      return Number(this.$route.params.clientId);
    },
    userId() {
      return Number(this.$route.params.userId);
    },
    userProfileImage () {
      return this.user?.profile_image?.url || '';
    },
    userInitials() {
      const firstInt = this.user?.first_name[0] || '';
      const lastInt = this.user?.last_name[0] || '';
      return `${firstInt}${lastInt}`;
    },
  },
  watch: {
    tabIndex: {
      handler() {
        const tabName = this.tabs.find((tab,i) => i === this.tabIndex);
        if(this.$route.hash !== tabName) {
          this.$router.push({ hash: `${tabName}` });
        }
      }, 
    },
    '$route.hash': {
      handler(n) {
        if(n) {
          const tabIndex = this.tabs.findIndex(tab => n === tab);
          this.tabIndex = tabIndex;
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.fetchUser();
  },
  methods: {
    onUserUpdated() {
      this.fetchUser();
    },
    async fetchUser() {
      try {
        this.isLoading = true;
        const response = await usersService.getUser(this.userId);
        this.user = response.data;
      } catch (e) {
        const { data } = e.response;
        this.$toast(makeErrorToast(data.message));
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    onUserRemoved() {
      this.$router.push({ name: 'admin-client', params: {clientId: Number(this.$route.params.clientId)}, hash: '#participants' });
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .tab-content {
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  padding: 5px;
}
</style>