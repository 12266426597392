<template>
  <section>
    <vue-good-table
      class="mt-3"
      mode="remote"
      :is-loading="isLoading"
      :columns="columns"
      :rows="rows"
      :rtl="directionIsRTL"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: perPage,
      }"
      style-class="vgt-table striped"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Status -->
        <span v-if="props.column.field === 'user_status'"> 
          <b-badge :variant="STATUS_COLOR[props.row.status.name]">
            {{ props.row.status.name }}
          </b-badge>
        </span>
        <!-- Column: Name -->
        <span v-else-if="props.column.field === 'name'"> 
          <del 
            v-if="props.row.deleted_at" 
            style="color:red;"
          >
            <router-link
              :to="{ name: 'admin-program', params: { id: props.row.id } }"
            >
              {{ props.row.name }} 
            </router-link>
          </del>
          <p v-else>
            <router-link
              :to="{ name: 'admin-program', params: { id: props.row.id } }"
              class="table-primary-link"
            >
              {{ props.row.name }}
            </router-link>
          </p>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <table-pagination
          :per-page="perPage"
          :total="total"
          @perPageChanged="
            (value) => props.perPageChanged({ currentPerPage: value })
          "
          @pageChanged="
            (value) => props.pageChanged({ currentPage: value })
          "
        />
      </template>
    </vue-good-table>
  </section>
</template>

<script>
import {
  BBadge,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import {
  STATUS_COLOR,
  GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
  GOODTABLE_LOCALISED_DATE_FORMAT,
  GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT,
  PRIMARY_DATE_FORMAT,
} from "@/libs/utils";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";
import programsService from "@/services/programsService";
import {programTypeDisplay} from '@models/enums/ePrograms';
import {userStatusSelect, participantRolesDisplay, userStatusDisplay, userRoles as userRolesModel, userRolesDisplay} from '@models';
import { makeErrorToast, localDateStringOrDateRangeStringToUtcRange } from "@/libs/utils";
import useAomTableConfig from "@aom-core/useAomTableConfig.js";

export default {
  components: {
    VueGoodTable,
    BBadge,
    TablePagination, 
  },
  props: {
    userId: {
      type: Number,
      default: 0
    },
    clientId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isLoading: false,
      dir: false,
      columns: [
        
        {
          label: "Name",
          field: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Type",
          field: "type.name",
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: programTypeDisplay.map(t => ({value:t.id, text: t.text})),
          },
        },
        {
          label: "User Role",
          field: "user_roles",
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: participantRolesDisplay
          },
        },
        {
          label: "User Date Added",
          field: "user_created_at",
          filterOptions: {
            enabled: true,
            placeholder: "Filter Date",
            filterFn: this.dateFilter,
          },
          tdClass: "search-date-selector",
          type: "date",
          dateInputFormat: GOODTABLE_ISO_DATETIME_INPUT_FORMAT,
          dateOutputFormat: GOODTABLE_LOCALISED_DATE_FORMAT,
        },
        {
          label: "User Status",
          field: "user_status",
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: userStatusSelect,
          },
        },
      ],
      columnFilters: [],
      sort: [],
      rows: [],
      searchTerm: '',
      programTypeDisplay,
    };
  },
  computed: {
    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
  },
  mounted() {
    const elements = document.getElementsByName("vgt-user_created_at");
    elements.forEach(function (input) {
      flatPickr(input, {
        enableTime: false,
        dateFormat: GOODTABLE_SEARCH_FIELD_DATE_PICKER_FORMAT, 
        allowInput: true,
        altInput: true,
        altFormat: PRIMARY_DATE_FORMAT,
        mode: "range"
      });
    });
  },
  created() {
    this.loadItems();
  },
  methods: {
    dateFilter(date, filterString) {
      return (date = Date.parse(date) >= Date.parse(filterString));
    },
    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type !== 'none') {
        this.sort = [{ field: field, type: type }];
      } else {
        this.sort = [];
      }
      this.loadItems();
    },

    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of [
        'user_status',
        'name',
        'type.name',
        'user_created_at',
        'user_roles'
      ]) {
        if (params.columnFilters[col]) {
          if(col === 'type.name'){
            columnFilters.push({
              field: 'type',
              value: params.columnFilters[col]
            });
          } else if(col === 'user_roles'){
            let roleIds = [];
            roleIds.push(params.columnFilters[col]);
            columnFilters.push({
              field: 'user_roles.role',
              value: roleIds
            });
          } else if (col === "user_created_at") {
            const dateRange = localDateStringOrDateRangeStringToUtcRange(params.columnFilters[col]);
            if (dateRange) {
              columnFilters.push({
                field: "user_roles.created_at",
                value: dateRange,
              });
            }
          } else if(col === 'user_status'){
            columnFilters.push({
              field: 'user_roles.user_status',
              value: params.columnFilters[col]
            });
          } else {
            columnFilters.push({
              field: col,
              value: params.columnFilters[col],
            });
          }
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },
    refreshList(){
      if(!this.isLoading){
        this.loadItems();
      }
    },
    async loadItems() {
      try {
        this.isLoading = true;
        const response = await programsService.getClientUserPrograms(this.clientId, this.userId, {
          page: this.page,
          perPage: this.perPage,
          columnFilters: this.columnFilters,
          sort: this.sort,
        });
        const {total, items} = response.data;
        this.total = total;
        this.rows = items.map(item => ({
          ...item,
          "user_created_at": item.user_roles[0].created_at,
          "user_status": userStatusDisplay[item.user_roles[0].status_id],
          "user_roles": this.transformUserRoles(item.user_roles)
        }));
      } catch (e) {
        this.$toast(makeErrorToast("Participant program list failed to load."));
        console.log(e);
        this.$log.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    transformUserRoles(userRoles) {
      return Array.from(
        (new Set(userRoles.filter(r => r.role_id === userRolesModel.MENTOR || r.role_id === userRolesModel.MENTEE).map(r => userRolesDisplay[r.role_id])))
      ).join(', ');
    },
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();

    return {
      STATUS_COLOR,
      total,
      perPage,
      page
    };
  },
};
</script>

<style>
  .search-date-selector{
    text-align: center !important;
  }
</style>

<style lang="scss" scoped>
tbody > tr > td {
  vertical-align: middle !important;
}

tr > td {
  vertical-align: middle !important;
}

.badge {
  font-weight: 400 !important;
}
</style>

<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
</style>
